import {
  LIST_SALE_CHANNEL,
  LIST_SALE_CHANNEL_FAIL,
  LIST_SALE_CHANNEL_SUCCESS,
} from "./actionType";

export const listSaleChannel = (options) => ({
  type: LIST_SALE_CHANNEL,
  payload: options,
});

export const listSaleChannelFail = (error) => ({
  type: LIST_SALE_CHANNEL_FAIL,
  payload: error,
});

export const listSaleChannelSuccess = (saleChannels) => ({
  type: LIST_SALE_CHANNEL_SUCCESS,
  payload: saleChannels,
});
