import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputTheme from "../../../Components/Common/InputTheme";
import SelectTheme from "../../../Components/Common/SelectTheme";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import { useDispatch } from "react-redux";
import useMemoizedSelector from "../../../Components/Hooks/useMemoizedSelector";
import { getLoyaltyApp, getVersion, updateLoyaltyApp } from "../../../store/actions";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ScrollToError from "../../../Components/Common/ScrollToError";
import BreadCrumbCustom from "../../../Components/Common/BreadCrumbCustom";
import { AccountTypes } from "../../../Components/constants/common";

export default function LoyaltyAppPage() {
  const { t } = useTranslation();
  document.title = `${t("Store information")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const formRef = useRef(null);

  const { loyaltyApp, loading } = useMemoizedSelector((state) => ({
    loyaltyApp: state.LoyaltyApp.loyaltyApp,
    loading: state.LoyaltyApp.loading,
  }));
  console.log(loyaltyApp);

  useEffect(() => {
    dispatch(getLoyaltyApp());
  }, [dispatch]);

  const validation = {
    enableReinitialize: true,
    initialValues: {
      default_price_group: loyaltyApp?.setting_value?.default_price_group,
      default_cash_payment_method: loyaltyApp?.setting_value?.default_cash_payment_method,
      default_bank_payment_method: loyaltyApp?.setting_value?.default_bank_payment_method,
      default_branch: loyaltyApp?.setting_value?.default_branch,
    },
    // validationSchema: Yup.object({
    //   default_price_group: Yup.object().required(t("Please Enter Email")),
    //   default_payment_method: Yup.object().required(t("Please Enter Phone Number")),
    // }),
    onSubmit: (values) => {
      const data = { ...values };
      dispatch(
        updateLoyaltyApp(data, () => {
          toast.success(t("Update loyalty app information success!"));
          setTimeout(() => {
            dispatch(getVersion());
          }, 2000);
        }),
      );
    },
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text={t("Loyal app information")} isBack={false}>
          <div className="hstack gap-2">
            <ButtonTheme
              className="btn btn-primary w-md"
              loading={loading}
              onClick={() => formRef.current.handleSubmit()}
            >
              {t("Save")}
            </ButtonTheme>
          </div>
        </BreadCrumbCustom>
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          innerRef={formRef}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <ScrollToError />
              <Card>
                <CardHeader>
                  <h4>{t("Loyal app information")}</h4>
                  <p>{t("The information used in zalo mini app")}</p>
                </CardHeader>
                <CardBody>
                  <Row>
                    {/* <Col lg={12}>
                      <InputTheme name="store_url" label={"Store URL"} />
                    </Col> */}
                    <Col lg={6}>
                      <SelectTheme
                        label={"Default price group"}
                        name="default_price_group"
                        placeholder={"Select Default Price"}
                        remote={true}
                        isObjectValueSelect={true}
                        selectDataOrigin={true}
                        path="price/price_groups"
                      />
                    </Col>
                    <Col lg={6}>
                      <SelectTheme
                        label={"Default branch"}
                        name="default_branch"
                        placeholder={"Select Default Branch"}
                        remote={true}
                        isObjectValueSelect={true}
                        selectDataOrigin={true}
                        path="inventory/locations"
                      />
                    </Col>
                    <Col lg={6}>
                      <SelectTheme
                        label={"Default cash payment method"}
                        name="default_cash_payment_method"
                        placeholder={"Select Cash Payment Method"}
                        remote={true}
                        isObjectValueSelect={true}
                        selectDataOrigin={true}
                        optionSearch={{ "account.type": AccountTypes.CASH }}
                        path="finance/payment_methods"
                      />
                    </Col>
                    <Col lg={6}>
                      <SelectTheme
                        label={"Default bank payment method"}
                        name="default_bank_payment_method"
                        placeholder={"Select Bank Payment Method"}
                        remote={true}
                        isObjectValueSelect={true}
                        selectDataOrigin={true}
                        optionSearch={{ "account.type": AccountTypes.BANK }}
                        path="finance/payment_methods"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
}
