import { LIST_INSTALLED_CHANNEL, LIST_INSTALLED_CHANNEL_SUCCESS, LIST_INSTALLED_CHANNEL_FAIL } from "./actionType";

const INIT_STATE = {
  installedChannels: [],
  error: null,
  success: false,
  loading: false,
  limit: 0,
  page: 0,
  total: 0,
};

const InstalledChannels = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_INSTALLED_CHANNEL:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };

    case LIST_INSTALLED_CHANNEL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        installedChannels: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
      };

    case LIST_INSTALLED_CHANNEL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default InstalledChannels;
