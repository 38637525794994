import { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Col, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { listStaticBanks } from "../../../../helpers/service_helper";

import { AccountTypeOptions, Actions } from "../../../../Components/constants/common";
import { useProfile } from "../../../../Components/Hooks/useProfile";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";

import { addNewAccount, updateAccount } from "../../../../store/actions";
import InputTheme from "../../../../Components/Common/InputTheme";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import ScrollToError from "../../../../Components/Common/ScrollToError";
import { deepCopy } from "../../../../helpers/format_helper";

const AddEditAccountModal = ({ account = null, isOpen, toggle }) => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();
  const { t } = useTranslation();

  const { accountsLoading, accountMessage, accountsAction } = useMemoizedSelector((state) => ({
    accountsLoading: state.Accounts.loading,
    accountsAction: state.Accounts.action,
    accountMessage: state.Accounts.message,
  }));

  const [bankList, setBankList] = useState([]);
  const [bankLoading, setBankLoading] = useState(false);

  useEffect(() => {
    const fetchBankList = async () => {
      try {
        setBankLoading(true);
        const res = await listStaticBanks();
        if (res) {
          setBankList(
            res.map((item) => {
              return { id: item.bin, name: item.short_name, data: item };
            }),
          );
        }
      } catch (e) {
        toast.error(String(e));
      } finally {
        setBankLoading(false);
      }
    };
    fetchBankList();
  }, []);

  const formRef = useRef();
  const validation = {
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: account?.name || "",
      type: account?.type || "",
      user_id: account?.user_id || userProfile.UserAttributes["sub"],
      account_name: account?.account_name || null,
      account_number: account?.account_number || null,
      bank_name: account?.bank_name || null,
      bank_id: account?.bank_id || null,
      note: account?.note || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Name")),
      type: Yup.string().required(t("Please Select Type")),
    }),
    onSubmit: (values) => {
      const payload = deepCopy(values);
      if (payload.type !== "BANK") {
        payload.account_name = null;
        payload.account_number = null;
        payload.bank_name = null;
        payload.bank_id = null;
      }
      if (account) {
        payload.id = account.id;
        dispatch(updateAccount(payload, handleToggle));
      } else {
        dispatch(addNewAccount(payload, handleToggle));
      }
    },
  };

  const handleToggle = () => {
    if (!accountsLoading) {
      toggle();
      formRef.current.resetForm();
    }
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={handleToggle} centered>
        <ModalHeader toggle={handleToggle}>{t(account ? "Edit account" : "Add new account")}</ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={validation.initialValues}
            validationSchema={validation.validationSchema}
            onSubmit={validation.onSubmit}
            innerRef={formRef}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <ScrollToError />
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <InputTheme name="name" label="Name" placeholder="Enter Name" />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <SelectTheme
                        name="type"
                        label={"Type"}
                        placeholder={"Select Type"}
                        options={AccountTypeOptions}
                      />
                    </div>
                  </Col>
                  {values?.type === "BANK" && (
                    <Fragment>
                      <Col lg={6}>
                        <div className="mb-3">
                          <InputTheme name="account_name" label="Account name" placeholder="Enter account name" />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <InputTheme name="account_number" label="Account number" placeholder="Enter account number" />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="mb-3">
                          <SelectTheme
                            label={"Bank name"}
                            name="bank_id"
                            placeholder={"Select Bank"}
                            options={bankList}
                            onChange={(data) => {
                              setFieldValue("bank_name", data.label);
                            }}
                          />
                        </div>
                      </Col>
                    </Fragment>
                  )}

                  <Col lg={12}>
                    <div className="mb-3">
                      <InputTheme name="note" label="Note" placeholder="Enter note" />
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
        <ModalFooter className="border-0">
          <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleToggle}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme
            type="button"
            className="btn btn-primary w-sm"
            loading={accountsLoading && (accountsAction === Actions.UPDATE || accountsAction === Actions.CREATE)}
            disabled={accountsLoading && (accountsAction === Actions.UPDATE || accountsAction === Actions.CREATE)}
            onClick={() => formRef.current.handleSubmit()}
          >
            {t(account ? "Update" : "Add")}
          </ButtonTheme>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddEditAccountModal;
