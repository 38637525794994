import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Redux States
import { LIST_SALE_CHANNEL } from "./actionType";

import { listSaleChannelSuccess, listSaleChannelFail } from "./action";

import { getInstalledChannelsAPI, listConnectionChannelsAPI } from "../../../../helpers/service_helper";

function* listSaleChannel({ payload: { ...res } }) {
  try {
    const response = yield call(listConnectionChannelsAPI, { ...res });
    // const installedResponse = yield call(getInstalledChannelsAPI, { limit: response.connection_types.length });
    yield put(listSaleChannelSuccess(response));
  } catch (error) {
    yield put(listSaleChannelFail(error));
  }
}

export function* watchListSaleChannel() {
  yield takeEvery(LIST_SALE_CHANNEL, listSaleChannel);
}

function* saleChannelsSaga() {
  yield all([fork(watchListSaleChannel)]);
}

export default saleChannelsSaga;
