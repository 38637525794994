import { Fragment, useEffect, useState } from "react";
import { Row } from "reactstrap";
import withRouter from "./withRouter";
import PaginationCustom from "./PaginationCustom";
import FilterComponent from "./FilterComponent";

const ListContainerCustom = ({
  showPagination = true,
  showSearch = true,
  filterType = "",
  numberOfFilters = 3,
  searchPlaceHolder = "Search...",
  listFilter = [],
  defaultSaveFilter = [],
  onCallData = () => {},
  limitPage = 10,
  currentPage,
  scrollHeight,
  totalItems,
  defaultPayload = { limit: 20, sort_created_at: "desc" },
  defaultDateFilter = true,
  tableView = false,
  isCheckbox = false,
  isSettings = false,
  isRefresh = true,
  loading,
  listAction = [],
  listHeader = [],
  children,
}) => {
  const [payload, setPayload] = useState({ limit: 20, sort_created_at: "desc" });
  // const [firstRun, setFirstRun] = useState(true);
  const KEY_SORT = "sort_";

  useEffect(() => {
    // if (firstRun) {
    //   setFirstRun(false);
    //   return;
    // }
    onCallData(payload);
  }, [payload]);

  return (
    <Fragment>
      {filterType && (
        <FilterComponent
          showSearch={showSearch}
          filterType={filterType}
          searchPlaceHolder={searchPlaceHolder}
          handleParamSearch={(dataPayload) => {
            const newPayload = { ...dataPayload };
            const arrKeyPayload = Object.keys(payload);
            for (let index = 0; index < arrKeyPayload.length; index++) {
              const key = arrKeyPayload[index];
              if (key.includes(KEY_SORT)) newPayload[key] = payload[key];
              if (key === "limit") newPayload[key] = payload[key];
            }
            setPayload(newPayload);
          }}
          defaultPayload={payload || {}}
          numberOfFilters={numberOfFilters}
          listFilter={listFilter}
          filterView={tableView}
          defaultSaveFilter={defaultSaveFilter}
          defaultDateFilter={defaultDateFilter}
          onCallData={onCallData}
          isRefresh={isRefresh}
          listLoading={loading}
        />
      )}
      {children}
      {showPagination && totalItems > 0 && (
        <Row className="mt-3">
          <PaginationCustom
            currentPage={currentPage}
            totalItems={totalItems}
            limit={limitPage}
            payload={payload}
            onGetApi={(data) => {
              setPayload(data);
            }}
          />
        </Row>
      )}
    </Fragment>
  );
};
export default withRouter(ListContainerCustom);
