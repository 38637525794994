import { LIST_INSTALLED_CHANNEL, LIST_INSTALLED_CHANNEL_FAIL, LIST_INSTALLED_CHANNEL_SUCCESS } from "./actionType";

export const listInstalledChannel = (options) => ({
  type: LIST_INSTALLED_CHANNEL,
  payload: options,
});

export const listInstalledChannelFail = (error) => ({
  type: LIST_INSTALLED_CHANNEL_FAIL,
  payload: error,
});

export const listInstalledChannelSuccess = (installedChannels) => ({
  type: LIST_INSTALLED_CHANNEL_SUCCESS,
  payload: installedChannels,
});
