import { api } from "../config";
import { Resource } from "../helpers/api_helper";

export const brandsAPI = new Resource("product/brands");
export const unitsAPI = new Resource("product/units");
export const productsAPI = new Resource("product/products");
export const variantsAPI = new Resource("product/variants");
export const categoriesAPI = new Resource("product/categories");

export const saveFilterAPI = new Resource("filter/filters");
export const priceGroupsAPI = new Resource("price/price_groups");

export const locationsAPI = new Resource("inventory/locations");
export const inventoryItemsAPI = new Resource("inventory/inventory_items");
export const stockAdjustmentsAPI = new Resource("inventory/stock_adjustments");
export const stockRelocatesAPI = new Resource("inventory/stock_relocates");

export const customersAPI = new Resource("actor/customers");
export const customerGroupsAPI = new Resource("actor/customer_groups");
export const suppliersAPI = new Resource("actor/suppliers");

export const purchaseOrdersAPI = new Resource("purchase_order/purchase_orders");

export const ordersAPI = new Resource("order/orders");
export const returnOrdersAPI = new Resource("order/return_orders");
export const paymentsAPI = new Resource("order/payments");
export const packagesAPI = new Resource("order/packages");

export const transactionVouchersAPI = new Resource("finance/vouchers");
export const paymentMethodsAPI = new Resource("finance/payment_methods");
export const transactionsAPI = new Resource("finance/transactions");
export const accountsAPI = new Resource("finance/accounts");

export const saleChannelsAPI = new Resource("sale_channel/sale_channels");
export const storesAPI = new Resource("sale_channel/stores");

export const shippingProvidersAPI = new Resource("/logistic/shipping_providers");
export const loyalCustomersAPI = new Resource("loyalty/loyal_customers");
export const loyaltyProgramsAPI = new Resource("loyalty/loyalty_programs");
export const rewardProgramsAPI = new Resource("loyalty/reward_programs");
export const tiersAPI = new Resource("loyalty/tiers");
export const pointEventsAPI = new Resource("loyalty/point_events");
export const tierEventsAPI = new Resource("loyalty/tier_events");

export const settingsAPI = new Resource("settings/settings");
export const settingsDefaultPrintAPI = new Resource("settings/settings/set-default-print");
export const printTemplatesAPI = new Resource("settings/template_print");

export const shiftsAPI = new Resource("/pos/shifts");
export const shiftPayLineItemsAPI = new Resource("/pos/shift_pay_line_items");
export const terminalsAPI = new Resource("/pos/terminals");
export const draftOrdersAPI = new Resource("/pos/draft_orders");

export const historyAPI = new Resource("history/history");

export const discountsAPI = new Resource("promotion/discounts");
export const vouchersAPI = new Resource("promotion/voucher");
export const voucherCodeAPI = new Resource("promotion/voucher_code");

export const roleAPI = new Resource("account/role");
export const permissionAPI = new Resource("account/permission");

export const blogsAPI = new Resource("blog/blogs");
export const blogCategoryAPI = new Resource("blog/categories");

export const connectionsAPI = new Resource(`connections`);

export const appStoresAPI = new Resource(`${api.API_URL_ONEX}/app_store`);
export const channelsAPI = new Resource(`${api.API_URL_ONEX}/channels`);
export const onexConnectionsAPI = new Resource(`${api.API_URL_ONEX}/connections`);
export const appsAPI = new Resource(`${api.API_URL_ONEX}/apps`);
export const actionsAPI = new Resource(`${api.API_URL_ONEX}/actions`);
