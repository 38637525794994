import React, { useEffect, useRef, useState } from "react";
import ModalContainer from "../../../../../Components/Common/ModalContainer";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import { Steps } from "antd";
import SchemaForm from "../../../../../Components/Common/SchemaForm";
import { getAuthLink, testConnection } from "../../../../../helpers/service_helper";
import { onexConnectionsAPI } from "../../../../../helpers/resource_helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ModalConfigSetting({ isOpen, toggle, data, onSubmitConfig, isEdit = false }) {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  const [index, setIndex] = useState(0);
  const items = data.map((item) => ({ key: item.data.name, title: item.data.name }));
  const [dataSchema, setDataSchema] = useState({});
  const [loading, setLoading] = useState(false);

  const [payloadSchema, setPayloadSchema] = useState([{}, {}]);
  const [formData, setFormData] = useState({});
  const refForm = useRef();
  const navigate = useNavigate();
  const handleSchema = (index, payloadSettingSchema) => {
    let settingSchema =
      Object.entries(data[index]?.schema?.schema?.definitions || {}).find(([key, schema]) =>
        key.endsWith("SettingsSchema"),
      )?.[1] || {};
    setDataSchema(settingSchema);
    setFormData(payloadSettingSchema ? payloadSettingSchema : payloadSchema[index]);
  };
  useEffect(() => {
    const newListSetting = data.map((item) => item?.data?.settings || {});
    if (isEdit) {
      setPayloadSchema(newListSetting);
    }
    handleSchema(0, isEdit ? newListSetting[0] : null);
  }, [data, isEdit]);

  const handleSubmitForm = async (dataForm, config) => {
    try {
      setLoading(true);
      const newList = payloadSchema;
      newList[config] = dataForm.formData;
      const base = data[config].data;
      base.settings = dataForm.formData;
      await onexConnectionsAPI.put({ ...base });
      await testConnection(base.channel.url, base.id);
      setLoading(false);
      setPayloadSchema(newList);
      if (config + 1 < items.length) {
        const dataIndex = current + 1;
        setCurrent(dataIndex);
        handleSchema(dataIndex);
      } else {
        onSubmitConfig();
      }
    } catch (error) {
      setLoading(false);
      setFormData(dataForm.formData);
      toast.error(error.toString());
    }
  };
  return (
    <ModalContainer
      isOpen={isOpen}
      toggle={toggle}
      title={t("Config setting")}
      size="lg"
      actionCustom={
        <>
          {current === 0 && (
            <ButtonTheme type="button" className="btn btn-light w-sm" onClick={toggle}>
              {t("Close")}
            </ButtonTheme>
          )}
          {current < items.length - 1 && (
            <ButtonTheme
              className="btn btn-secondary w-sm"
              onClick={() => {
                refForm.current.onTriggerClick();
              }}
              loading={loading}
            >
              {t("Next")}
            </ButtonTheme>
          )}
          {current > 0 && (
            <ButtonTheme
              className="btn btn-light w-sm"
              onClick={() => {
                setCurrent(current - 1);
                handleSchema(current - 1);
              }}
            >
              {t("Previous")}
            </ButtonTheme>
          )}
          {current === items.length - 1 && (
            <ButtonTheme
              className="btn btn-secondary w-sm"
              onClick={() => {
                refForm.current.onTriggerClick();
              }}
              loading={loading}
            >
              {t("Done")}
            </ButtonTheme>
          )}
        </>
      }
    >
      <Steps current={current} items={items} />
      <div className="mt-4">
        <SchemaForm
          schema={dataSchema}
          onSubmit={handleSubmitForm}
          formData={formData}
          hiddenButtonSubmit={true}
          innerRef={refForm}
          config={current}
        />
      </div>
    </ModalContainer>
  );
}
