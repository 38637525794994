import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { createConnectionChannel, getConnectionSetupFields } from "../../../../../helpers/service_helper";
import withRouter from "../../../../../Components/Common/withRouter";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import { convertToPascalCase, snakeToCapitalizedWords } from "../../../../../helpers/format_helper";
import InputTheme from "../../../../../Components/Common/InputTheme";
import ScrollToError from "../../../../../Components/Common/ScrollToError";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import Loader from "../../../../../Components/Common/Loader";
import { useDispatch } from "react-redux";
import { listSaleChannel } from "../../../../../store/actions";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import ImageCustom from "../../../../../Components/Common/ImageCustom";
import { toast } from "react-toastify";

const NewChannelPage = (props) => {
  const {
    router: { navigate, stockRelocate, params, t },
  } = props;
  const { connection_type } = params;
  const dispatch = useDispatch();
  const [formFields, setFormFields] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSchema, setLoadingSchema] = useState(false);
  const formRef = useRef();

  const [channel, setChannel] = useState(null);

  useEffect(() => {
    dispatch(listSaleChannel({}));
  }, [dispatch]);

  const { saleChannels, saleChannelsLoading, saleChannelsSuccess, saleChannelsError } = useMemoizedSelector(
    (state) => ({
      saleChannels: state.SaleChannels.saleChannels,
      saleChannelsLoading: state.SaleChannels.loading,
      saleChannelsSuccess: state.SaleChannels.success,
      saleChannelsError: state.SaleChannels.error,
    }),
  );

  useEffect(() => {
    if (!saleChannelsLoading) {
      setChannel(saleChannels.find((item) => item.key === connection_type));
    }
  }, [saleChannelsLoading, saleChannels]);

  useEffect(() => {
    const fetchFields = async () => {
      try {
        setLoadingSchema(true);
        const res = await getConnectionSetupFields(connection_type);
        const settingSchema =
          res?.setup_fields?.definitions?.[`${convertToPascalCase(connection_type)}SettingsSchema`] || {};
        const fields = Object.entries(settingSchema.properties || {}).map(([key, value]) => ({
          name: key,
          label:
            settingSchema.required && settingSchema.required.includes(key) ? (
              <>
                {snakeToCapitalizedWords(key)} <span style={{ color: "red" }}>*</span>
              </>
            ) : (
              snakeToCapitalizedWords(key)
            ),
          type: value.type === "string" ? (key.toLowerCase().includes("password") ? "password" : "text") : value.type,
          placeholder: `Enter ${snakeToCapitalizedWords(key)}`,
        }));

        setFormFields(fields);

        const initValues = Object.keys(settingSchema.properties || {}).reduce((acc, field) => {
          acc[field] = settingSchema.properties?.[field]?.default ?? "";
          return acc;
        }, {});
        setInitialValues(initValues);

        const validationObj = Yup.object().shape(
          Object.keys(settingSchema.properties || {}).reduce((acc, key) => {
            const field = settingSchema.properties[key];
            let fieldSchema = Yup.mixed();

            if (field.type === "string") {
              fieldSchema = Yup.string();
              // if (key.toLowerCase().includes("url")) {
              //   fieldSchema = fieldSchema.url(`Invalid URL format for ${convertCamelCaseToWords(key)}`);
              // }
            } else if (field.type === "number") {
              fieldSchema = Yup.number();
            } else if (field.type === "boolean") {
              fieldSchema = Yup.boolean();
            }

            if (settingSchema.required && settingSchema.required.includes(key)) {
              fieldSchema = fieldSchema.required(`${snakeToCapitalizedWords(key)} is required`);
            }

            acc[key] = fieldSchema;
            return acc;
          }, {}),
        );

        setValidationSchema(validationObj);
      } catch (error) {
        console.error("Error fetching form fields:", error);
      } finally {
        setLoadingSchema(false);
      }
    };

    fetchFields();
  }, [connection_type]);

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      const payload = {
        connection_type: connection_type,
        settings: data,
      };
      const res = await createConnectionChannel(payload);
      toast.error(res?.message);
      if (res?.authorization_url) {
        window.open(res.authorization_url, "_blank");
      }
      navigate(`/channels/connections/${res.connection_id}`);
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text={t("Back to channel")} navigateTo="/channels">
          <div className="hstack gap-2">
            <ButtonTheme
              className="btn btn-primary w-md"
              loading={loading}
              onClick={() => {
                formRef.current.handleSubmit();
              }}
            >
              {t("Install")}
            </ButtonTheme>
          </div>
        </BreadCrumbCustom>

        {loadingSchema || saleChannelsLoading ? (
          <Loader />
        ) : (
          <Card>
            <CardHeader>
              <Row className="align-items-center gy-3">
                <div className="col-sm">
                  <h5 className="card-title mb-0">
                    {t("Install")} {channel?.name}
                  </h5>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <Row className="g-3">
                <Col xs={12}>
                  <div className="hstack gap-3">
                    <ImageCustom
                      image={channel?.logo}
                      name={channel?.name}
                      avatarSize={"avatar-md"}
                      isPreview={false}
                    />
                    <p className="mb-0">{channel?.description}</p>
                  </div>
                </Col>
                <Col xs={12}>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    innerRef={formRef}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <ScrollToError />
                        {formFields.map((field) => (
                          <InputTheme
                            key={field.name}
                            name={field.name}
                            label={field.label}
                            placeholder={field.placeholder}
                          />
                        ))}
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
};

export default withRouter(NewChannelPage);
