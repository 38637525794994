import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import { Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { listProduct } from "../../../../store/actions";
import { formatVNDCurrency } from "../../../../helpers/format_helper";
import SimpleBar from "simplebar-react";
import Loader from "../../../../Components/Common/Loader";
import NotFound from "../../../../Components/Common/NotFound";

const BestSellingProducts = ({ bestSellingProducts, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">{t("Best Selling Products")}</h4>
        </CardHeader>
        <CardBody>
          <SimpleBar style={{ maxHeight: "400px" }}>
            <table className="table table-hover table-borderless table-centered align-middle table-nowrap mb-0">
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={1000}>
                      <Loader />
                    </td>
                  </tr>
                ) : bestSellingProducts.length > 0 ? (
                  bestSellingProducts.map((item, key) => (
                    <tr key={key}>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <div className="flex-shrink-0">
                            <ImageCustom image={item.images?.[0]?.url} name={item.name} avatarSize={"avatar-sm"} /> 
                          </div>
                          <div>
                            <Tooltip title={t(item.name)}>
                              <h5 className="fs-14 my-1">
                                <Link to={`/report/products/${item.id}`} className="text-truncate-two-lines">
                                  {t(item.name)}
                                </Link>
                              </h5>
                            </Tooltip>
                            <span className="text-muted">{item.sku}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal text-center">
                          {formatVNDCurrency(item?.revenue?.value || 0)}
                        </h5>
                        <span className="text-muted text-center">{t("Revenue")}</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal text-center">{item?.total_quantity?.value || 0}</h5>
                        <span className="text-muted text-center">{t("Total quantity")}</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal text-center">{item?.unique_orders?.value || 0}</h5>
                        <span className="text-muted text-center">{t("Unique orders")}</span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10000}>
                      <NotFound />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </SimpleBar>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default BestSellingProducts;
