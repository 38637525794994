import axios from "axios";
import { api } from "../config";
import { getInfoAPI, refreshTokenAPI } from "./AuthAPI";

const defaultConfig = {
  baseURL: api.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

// Create Axios instance with default configuration
const defaultAxios = axios.create(defaultConfig);

// Axios instance for requests using access token
const tokenAxios = axios.create(defaultConfig);

const getToken = () => {
  try {
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    return auth_user?.Token?.IdToken || null;
  } catch (e) {
    return null;
  }
};

const getAccessToken = () => {
  try {
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    return auth_user?.Token?.AccessToken || null;
  } catch (e) {
    return null;
  }
};

const getRefreshToken = () => {
  try {
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    return auth_user?.Token?.RefreshToken || null;
  } catch (e) {
    return null;
  }
};

const getTokenExpiresIn = () => {
  try {
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    return auth_user?.Token?.ExpiresIn || null;
  } catch (e) {
    return null;
  }
};

const getLoggedInUser = () => {
  try {
    const user = localStorage.getItem("auth_user");
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.error("Invalid auth user", String(error));
    return null;
  }
};

class APIClient {
  constructor(axiosInstance) {
    this.axiosInstance = axiosInstance;
  }
  get = (url, params) => {
    const queryString = params
      ? Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")
      : "";
    return this.axiosInstance.get(`${url}${queryString ? `?${queryString}` : ""}`);
  };

  create = (url, data) => {
    return this.axiosInstance.post(url, data);
  };

  update = (url, data) => {
    return this.axiosInstance.patch(url, data);
  };

  put = (url, data, config) => {
    return this.axiosInstance.put(url, data, { ...config });
  };

  delete = (url, config) => {
    return this.axiosInstance.delete(url, config);
  };
}

export const defaultApiCli = new APIClient(defaultAxios);
export const apiCli = new APIClient(tokenAxios);

tokenAxios.interceptors.request.use(
  (config) => {
    const token = getToken();
    const access_token = getAccessToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (access_token) {
      config.params = { ...config.params, access_token };
    }

    if (config.url === "auth/refresh") {
      delete config.params;
      delete config.headers["Authorization"];
    }
    return config;
  },
  (error) => Promise.reject(error),
);

let isRefreshing = false;
let failedQueue = [];
let lastRefreshTime = 0;

function addFailedQueue(callback) {
  failedQueue.push(callback);
}

function callBackFailedRequests() {
  failedQueue.forEach((callback) => callback());
  failedQueue = [];
}

tokenAxios.interceptors.response.use(
  (response) => response.data || response,
  async (error) => {
    const originalRequest = error.config;
    let errorMessage = error?.response?.data?.message;

    switch (error?.response?.status) {
      case 500:
        errorMessage = errorMessage || "Internal Server Error";
        break;
      case 401:
        errorMessage = errorMessage || "Invalid credentials";

        if (!originalRequest._retry && getToken() && errorMessage === "Unauthorized") {
          const retryOriginalRequest = new Promise((resolve) => {
            addFailedQueue(() => resolve(tokenAxios.request(originalRequest)));
          });
          const currentTime = Date.now();
          const expires_in = getTokenExpiresIn();
          if (!isRefreshing) {
            try {
              if (expires_in && currentTime - lastRefreshTime >= expires_in * 1000) {
                isRefreshing = true;
                lastRefreshTime = currentTime;
                const refreshToken = getRefreshToken();
                const res = await refreshTokenAPI({
                  refresh_token: refreshToken,
                });
                const auth_user = JSON.parse(localStorage.getItem("auth_user"));
                const newToken = { ...auth_user.Token, ...res };
                auth_user.Token = newToken;
                localStorage.setItem("auth_user", JSON.stringify(auth_user));
                originalRequest.headers.Authorization = `Bearer ${getToken()}`;
                callBackFailedRequests();
                // if original req failed with 401 again - it means server returned not valid token for refresh request
              } else {
                // from last refresh time to next refresh time is less than expired time then log out account
                window.location.href = "/logout";
              }
            } catch (err) {
              window.location.href = "/logout";
            } finally {
              isRefreshing = false;
            }
          }
          return retryOriginalRequest;
        }
        break;
      case 404:
        errorMessage = errorMessage || "Sorry! The data you are looking for could not be found";
        break;
      default:
        errorMessage = errorMessage || "Something wrong";
    }
    return Promise.reject(errorMessage);
  },
);

defaultAxios.interceptors.response.use(
  (response) => response.data || response,
  async (error) => {
    let errorMessage = error?.response?.data?.message;
    return Promise.reject(errorMessage);
  },
);

const setAuthorization = (token) => {
  tokenAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

class Resource {
  constructor(resourceName, isPublic = false) {
    this.resourceName = resourceName;
    this.api_client = new APIClient(isPublic ? defaultAxios : tokenAxios);
  }

  list = (params) => this.api_client.get(this.resourceName, params);

  customList = (customPath, params) => this.api_client.get(`${this.resourceName}/${customPath}`, params);

  get = ({ id: resourceId }) => this.api_client.get(`${this.resourceName}/${resourceId}`);

  filter = (params) => this.api_client.get(this.resourceName, params);

  create = (payload) => this.api_client.create(this.resourceName, payload);

  customCreate = (customPath, payload) => this.api_client.create(`${this.resourceName}/${customPath}`, payload);

  createCustomPath = (customPath, payload) => this.api_client.create(`${this.resourceName}/${customPath}`, payload);

  update = (payload) => this.api_client.update(`${this.resourceName}/${payload.id}`, payload);

  put = (payload) => this.api_client.put(`${this.resourceName}/${payload.id}`, payload);

  customPut = (customPath, payload) => this.api_client.put(`${this.resourceName}/${customPath}`, payload);

  delete = ({ id: resourceId }) => this.api_client.delete(`${this.resourceName}/${resourceId}`);

  customDelete = (customPath) => this.api_client.delete(`${this.resourceName}/${customPath}`);
}

export { APIClient, Resource, setAuthorization, getLoggedInUser };
