import {
  LIST_SALE_CHANNEL,
  LIST_SALE_CHANNEL_SUCCESS,
  LIST_SALE_CHANNEL_FAIL,
} from "./actionType";

const INIT_STATE = {
  saleChannels: [],
  error: null,
  success: false,
  loading: false,
};

const SaleChannels = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_SALE_CHANNEL:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };

    case LIST_SALE_CHANNEL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        saleChannels: action.payload.connection_types ?? [],
      };

    case LIST_SALE_CHANNEL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default SaleChannels;
