import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ChangePassSaga from "./auth/changepwd/saga";
import ResetPassSaga from "./auth/resetpwd/saga";

//ecommerce
import productsSaga from "./ecommerce/product/products/saga";
import variantsSaga from "./ecommerce/product/variants/saga";
import brandsSaga from "./ecommerce/product/brands/saga";
import unitsSaga from "./ecommerce/product/units/saga";
import categoriesSaga from "./ecommerce/product/categories/saga";
import locationsSaga from "./ecommerce/inventory/locations/saga";
import inventoryItemsSaga from "./ecommerce/inventory/inventory_items/saga";
import priceGroupsSaga from "./ecommerce/price/price_groups/saga";
import ordersSaga from "./ecommerce/order/orders/saga";
import returnsSaga from "./ecommerce/order/return_orders/saga";
import packagesSaga from "./ecommerce/order/packages/saga";
import customersSaga from "./ecommerce/customer/customers/saga";
import customerGroupsSaga from "./ecommerce/customer/customer_groups/saga";
import suppliersSaga from "./ecommerce/purchase_order/suppliers/saga";
import purchaseOrdersSaga from "./ecommerce/purchase_order/purchase_orders/saga";
import paymentMethodsSaga from "./ecommerce/finance/payment_methods/saga";
import TransactionVouchersSaga from "./ecommerce/finance/vouchers/saga";
import AccountsSaga from "./ecommerce/finance/accounts/saga";
import TransactionsSaga from "./ecommerce/finance/transactions/saga";
import SaleChannelsSaga from "./ecommerce/sale_channel/sale_channels/saga";
import InstalledChannelsSaga from "./ecommerce/sale_channel/installed_channels/saga";
import StoresSaga from "./ecommerce/sale_channel/stores/saga";
import ShippingProvidersSaga from "./ecommerce/logistic/shipping_providers/saga";
import ImportsSaga from "./ecommerce/import/imports/saga";
import RecordsSaga from "./ecommerce/import/records/saga";
import ActivitiesSaga from "./ecommerce/activities/saga";
import RewardProgramsSaga from "./ecommerce/loyalty/reward_programs/saga";
import LoyaltyProgramsSaga from "./ecommerce/loyalty/loyalty_programs/saga";
import DiscountsSaga from "./ecommerce/promotion/discounts/saga";
import VouchersSaga from "./ecommerce/promotion/vouchers/saga";
import BlogsSaga from "./ecommerce/blog/blogs/saga";
import PermissionSaga from "./auth/permission/saga";
import BlogCategoriesSaga from "./ecommerce/blog/categories/saga";
import ShiftsSaga from "./ecommerce/pos/shifts/saga";
import TerminalsSaga from "./ecommerce/pos/terminals/saga";
import DraftOrdersSaga from "./ecommerce/pos/draft_orders/saga";
import StockAdjustmentsSaga from "./ecommerce/inventory/stock_adjustments/saga";
import StockRelocatesSaga from "./ecommerce/inventory/stock_relocates/saga";
import RolesSaga from "./ecommerce/settings/roles/saga";
import LoyaltyAppSaga from "./ecommerce/loyalty_app/saga";

// Dashboard Ecommerce
import dashboardEcommerceSaga from "./dashboardEcommerce/saga";
import shopInfoSaga from "./shop_info/saga";
import TemplatePrintSaga from "./template_print/saga";
import VersionSaga from "./version/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ChangePassSaga),
    fork(ResetPassSaga),
    fork(PermissionSaga),
    fork(productsSaga),
    fork(variantsSaga),
    fork(brandsSaga),
    fork(unitsSaga),
    fork(categoriesSaga),
    fork(locationsSaga),
    fork(inventoryItemsSaga),
    fork(priceGroupsSaga),
    fork(ordersSaga),
    fork(returnsSaga),
    fork(customersSaga),
    fork(suppliersSaga),
    fork(packagesSaga),
    fork(purchaseOrdersSaga),
    fork(paymentMethodsSaga),
    fork(TransactionVouchersSaga),
    fork(AccountsSaga),
    fork(TransactionsSaga),
    fork(SaleChannelsSaga),
    fork(InstalledChannelsSaga),
    fork(StoresSaga),
    fork(ShippingProvidersSaga),
    fork(ImportsSaga),
    fork(RecordsSaga),
    fork(ActivitiesSaga),
    fork(dashboardEcommerceSaga),
    fork(customerGroupsSaga),
    fork(RewardProgramsSaga),
    fork(LoyaltyProgramsSaga),
    fork(DiscountsSaga),
    fork(VouchersSaga),
    fork(BlogsSaga),
    fork(shopInfoSaga),
    fork(TemplatePrintSaga),
    fork(BlogCategoriesSaga),
    fork(ShiftsSaga),
    fork(TerminalsSaga),
    fork(DraftOrdersSaga),
    fork(StockAdjustmentsSaga),
    fork(StockRelocatesSaga),
    fork(RolesSaga),
    fork(VersionSaga),
    fork(LoyaltyAppSaga),
  ]);
}
