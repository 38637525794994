import React, { Fragment, useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardFooter,
} from "reactstrap";
import {
  authorizationConnection,
  getConnectionDetails,
  getConnectionSetupFields,
  setFetchActionSettings,
  setPublishActionSettings,
  testConnectionAPI,
  updateConnectionSettings,
  updateWebHookSettings,
} from "../../../../../helpers/service_helper";
import withRouter from "../../../../../Components/Common/withRouter";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import {
  convertToPascalCase,
  deepCopy,
  isValidJson,
  snakeToCapitalizedWords,
} from "../../../../../helpers/format_helper";
import InputTheme from "../../../../../Components/Common/InputTheme";
import ScrollToError from "../../../../../Components/Common/ScrollToError";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import Loader from "../../../../../Components/Common/Loader";
import { useDispatch } from "react-redux";
import ImageCustom from "../../../../../Components/Common/ImageCustom";
import { toast } from "react-toastify";
import classnames from "classnames";
import SelectTheme from "../../../../../Components/Common/SelectTheme";
import { useTranslation } from "react-i18next";
import BadgeCustom from "../../../../../Components/Common/BadgeCustom";

const ConfigConnectionPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  const { id } = params;
  const dispatch = useDispatch();
  const [connection, setConnection] = useState(null);

  const [formFields, setFormFields] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [loading, setLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [loadingSchema, setLoadingSchema] = useState(false);
  const formRef = useRef();

  const [selectedAction, setSelectedAction] = useState(null);
  const [actionType, setActionType] = useState(null);

  const handleSaveActionSettings = async (values) => {
    try {
      setLoading(true);

      const processActions = (actionData) => {
        const { actions, ...checkedActions } = actionData;
        const payload = { actions: {} };

        Object.keys(checkedActions).forEach((item) => {
          if (checkedActions[item] && actions[item]) {
            payload.actions[item] = actions[item];
          }
        });

        return payload;
      };
      const fetch_actions_payload = processActions(deepCopy(values.fetch_actions));
      const publish_actions_payload = processActions(deepCopy(values.publish_actions));
      await setFetchActionSettings(id, fetch_actions_payload);
      await setPublishActionSettings(id, publish_actions_payload);
      toast.success(t("Save action settings successfully!"));
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateWebhookSettings = async (values) => {
    try {
      setLoading(true);
      const payload = {
        webhook_settings: values,
      };
      const res = await updateWebHookSettings(id, payload);
      toast.success(res?.message);
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  const actionValidation = {
    enableReinitialize: true,
    initialValues: {
      fetch_actions: {
        actions: Object.values(connection?.action_groups ?? {}).reduce((total, item) => {
          const newData = { ...total, ...item.fetch_actions };
          return newData;
        }, {}),
        ...Object.values(connection?.action_groups ?? {}).reduce((total, item) => {
          const newData = {
            ...total,
            ...Object.keys(item.fetch_actions).reduce((subTotal, subItem) => {
              subTotal[subItem] = true;
              return subTotal;
            }, {}),
          };
          return newData;
        }, {}),
      },
      publish_actions: {
        actions: Object.values(connection?.action_groups ?? {}).reduce((total, item) => {
          const newData = { ...total, ...item.publish_actions };
          return newData;
        }, {}),
        ...Object.values(connection?.action_groups ?? {}).reduce((total, item) => {
          const newData = {
            ...total,
            ...Object.keys(item.publish_actions).reduce((subTotal, subItem) => {
              subTotal[subItem] = true;
              return subTotal;
            }, {}),
          };
          return newData;
        }, {}),
      },
    },
    onSubmit: (values) => {
      handleSaveActionSettings(values);
    },
  };
  const actionFormRef = useRef();

  const webHookValidation = {
    enableReinitialize: true,
    initialValues: {
      "orders/create": connection?.webhook_settings?.["orders/create"] ?? false,
      "products/create": connection?.webhook_settings?.["products/create"] ?? false,
      "inventory_levels/update": connection?.webhook_settings?.["inventory_levels/update"] ?? false,
      "orders/updated": connection?.webhook_settings?.["orders/updated"] ?? false,
      "products/update": connection?.webhook_settings?.["products/update"] ?? false,
    },
    onSubmit: (values) => {
      handleUpdateWebhookSettings(values);
    },
  };
  const webHookFormRef = useRef();

  const [authorizeLoading, setAuthorizeLoading] = useState(false);
  const authorize = async () => {
    try {
      setAuthorizeLoading(true);
      const res = await authorizationConnection(id);
      window.open(res.authorization_url, "_blank");
    } catch (e) {
      toast.error(String(e));
    } finally {
      setAuthorizeLoading(false);
    }
  };

  const tabs = ["Actions", "Webhook", "Credentials"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const toggleCustom = (tab) => {
    if (selectedTab !== tab) {
      setSelectedTab(tab);
      setActionType(null);
      setSelectedAction(null);
    }
  };

  useEffect(() => {
    const fetchFields = async () => {
      try {
        setLoadingSchema(true);
        const connectionRes = await getConnectionDetails(id);
        setConnection(connectionRes);
        const res = await getConnectionSetupFields(connectionRes?.channel_name);
        const settingSchema =
          res?.setup_fields?.definitions?.[`${convertToPascalCase(connectionRes?.channel_name)}SettingsSchema`] || {};
        const fields = Object.entries(settingSchema.properties || {}).map(([key, value]) => ({
          name: key,
          label:
            settingSchema.required && settingSchema.required.includes(key) ? (
              <>
                {snakeToCapitalizedWords(key)} <span style={{ color: "red" }}>*</span>
              </>
            ) : (
              snakeToCapitalizedWords(key)
            ),
          type:
            value.type === "string"
              ? settingSchema.required && settingSchema.required.includes(key)
                ? "password"
                : "text"
              : value.type,
          placeholder: `Enter ${snakeToCapitalizedWords(key)}`,
        }));

        setFormFields(fields);
        setInitialValues(connectionRes?.settings ?? {});

        const validationObj = Yup.object().shape(
          Object.keys(settingSchema.properties || {}).reduce((acc, key) => {
            const field = settingSchema.properties[key];
            let fieldSchema = Yup.mixed();

            if (field.type === "string") {
              fieldSchema = Yup.string();
              // if (key.toLowerCase().includes("url")) {
              //   fieldSchema = fieldSchema.url(`Invalid URL format for ${convertCamelCaseToWords(key)}`);
              // }
            } else if (field.type === "number") {
              fieldSchema = Yup.number();
            } else if (field.type === "boolean") {
              fieldSchema = Yup.boolean();
            }

            if (settingSchema.required && settingSchema.required.includes(key)) {
              fieldSchema = fieldSchema.required(`${snakeToCapitalizedWords(key)} is required`);
            }

            acc[key] = fieldSchema;
            return acc;
          }, {}),
        );
        setValidationSchema(validationObj);
      } catch (error) {
        console.error("Error fetching form fields:", error);
      } finally {
        setLoadingSchema(false);
      }
    };
    fetchFields();
  }, [id]);

  const handleUpdateSettings = async () => {
    try {
      console.log(formRef.current.values);
      setLoading(true);
      const payload = {
        settings: formRef.current.values,
      };
      const res = await updateConnectionSettings(id, payload);
      toast.success(t(res?.message));
      if (res?.authorization_url) {
        window.open(res.authorization_url, "_blank");
      }
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  const handleTestConnection = async () => {
    try {
      setTestLoading(true);
      const res = await testConnectionAPI(id);
      if (res?.status === "failure") {
        toast.error(t(res?.message));
      } else {
        toast.success(t(res?.message));
      }
    } catch (e) {
      toast.error(String(e));
    } finally {
      setTestLoading(false);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text={t("Back to channel")} navigateTo="/channels">
          <div className="hstack gap-2">
            <ButtonTheme className="btn btn-primary w-md" loading={testLoading} onClick={handleTestConnection}>
              {t("Test connection")}
            </ButtonTheme>
          </div>
        </BreadCrumbCustom>

        {loadingSchema ? (
          <Loader />
        ) : (
          <Card>
            <CardHeader>
              <Row className="align-items-center gy-3">
                <div className="col-sm">
                  <h5 className="card-title mb-0 hstack justify-content-between">
                    {connection?.name} {t("Configuration")} <BadgeCustom status={connection?.status} />
                  </h5>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <Row className="g-3">
                <Col xs={12}>
                  <div className="hstack gap-3">
                    <ImageCustom
                      image={connection?.logo}
                      name={connection?.name}
                      avatarSize={"avatar-md"}
                      isPreview={false}
                    />
                    <p className="mb-0">{connection?.description}</p>
                  </div>
                </Col>

                <Col xs={12}>
                  <Nav className="nav-tabs nav-tabs-custom nav-primary mb-3" role="tablist">
                    {tabs.map((item, index) => (
                      <NavItem key={index}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: selectedTab === item,
                          })}
                          onClick={() => {
                            toggleCustom(item);
                          }}
                        >
                          {t(item)}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={selectedTab} className="text-muted">
                    <TabPane tabId={tabs[0]} id={tabs[0]}>
                      <Formik
                        enableReinitialize={true}
                        initialValues={actionValidation.initialValues}
                        validationSchema={actionValidation.validationSchema}
                        onSubmit={actionValidation.onSubmit}
                        innerRef={actionFormRef}
                      >
                        {({ values, submitForm, setFieldValue }) => (
                          <Form>
                            {!selectedAction && !actionType ? (
                              <div className="space-y-6">
                                <div>
                                  {Object.values(connection?.action_groups ?? {})
                                    .filter(
                                      (item) =>
                                        Object.keys(item.fetch_actions).length !== 0 ||
                                        Object.keys(item.publish_actions).length !== 0,
                                    )
                                    .map((item, index) => (
                                      <Row key={index}>
                                        <Col lg={12}>
                                          <h5 className="card-title flex-grow-1 mb-2">
                                            {t(snakeToCapitalizedWords(item.group_name))}
                                          </h5>
                                        </Col>
                                        <Col lg={6}>
                                          {Object.keys(item.fetch_actions).map((action) => (
                                            <div
                                              key={action}
                                              className="hstack align-items-center justify-content-between mb-2"
                                            >
                                              <InputTheme
                                                type="checkbox"
                                                name={`fetch_actions.${action}`}
                                                label={action}
                                                formNoMargin={true}
                                              />
                                              <ButtonTheme
                                                className="btn btn-outline-primary"
                                                onClick={() => {
                                                  setSelectedAction(action);
                                                  setActionType("fetch_actions");
                                                }}
                                              >
                                                <i className="ri-settings-5-line me-2"></i>
                                                {t("Settings")}
                                              </ButtonTheme>
                                            </div>
                                          ))}
                                        </Col>
                                        <Col lg={6}>
                                          {Object.keys(item.publish_actions).map((action) => (
                                            <div
                                              key={action}
                                              className="hstack align-items-center justify-content-between mb-2"
                                            >
                                              <InputTheme
                                                type="checkbox"
                                                name={`publish_actions.${action}`}
                                                label={action}
                                                formNoMargin={true}
                                              />
                                              <ButtonTheme
                                                className="btn btn-outline-primary"
                                                onClick={() => {
                                                  setSelectedAction(action);
                                                  setActionType("publish_actions");
                                                }}
                                              >
                                                <i className="ri-settings-5-line me-2"></i>
                                                {t("Settings")}
                                              </ButtonTheme>
                                            </div>
                                          ))}
                                        </Col>
                                      </Row>
                                    ))}
                                </div>
                                <div className="hstack gap-2 justify-content-end">
                                  <ButtonTheme
                                    loading={loading}
                                    loadShowText={true}
                                    disabled={loading}
                                    className="btn btn-outline-primary"
                                    onClick={submitForm}
                                  >
                                    {t("Save Action Settings")}
                                  </ButtonTheme>
                                </div>
                              </div>
                            ) : (
                              <ActionSetting
                                initialValues={values?.[actionType]?.actions?.[selectedAction]}
                                onChangeData={(data) => {
                                  setFieldValue(`${[actionType]}.actions.${[selectedAction]}`, data);
                                  setActionType(null);
                                  setSelectedAction(null);
                                }}
                                onBack={() => {
                                  setActionType(null);
                                  setSelectedAction(null);
                                }}
                                selectedAction={selectedAction}
                                actionType={actionType}
                              />
                            )}
                          </Form>
                        )}
                      </Formik>
                    </TabPane>
                    <TabPane tabId={tabs[1]} id={tabs[1]}>
                      <Formik
                        enableReinitialize={true}
                        initialValues={webHookValidation.initialValues}
                        validationSchema={webHookValidation.validationSchema}
                        onSubmit={webHookValidation.onSubmit}
                        innerRef={webHookFormRef}
                      >
                        {({ values, submitForm, setFieldValue }) => (
                          <Form>
                            <InputTheme type="checkbox" name={"orders/create"} label="Orders Create" />
                            <InputTheme type="checkbox" name={"products/create"} label="Products Create" />
                            <InputTheme
                              type="checkbox"
                              name={"inventory_levels/update"}
                              label="Inventory Levels Update"
                            />
                            <InputTheme type="checkbox" name={"orders/updated"} label="Order Update" />
                            <InputTheme type="checkbox" name={"products/update"} label="Products Update" />
                            <div className="hstack gap-2 justify-content-end">
                              <ButtonTheme
                                loading={loading}
                                loadShowText={true}
                                disabled={loading}
                                className="btn btn-outline-primary"
                                onClick={submitForm}
                              >
                                {t("Save Webhook Settings")}
                              </ButtonTheme>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </TabPane>
                    <TabPane tabId={tabs[2]} id={tabs[2]}>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleUpdateSettings}
                        enableReinitialize
                        innerRef={formRef}
                      >
                        {({ submitForm }) => (
                          <Form>
                            <ScrollToError />
                            {formFields.map((field) => (
                              <InputTheme
                                type={field.type}
                                key={field.name}
                                name={field.name}
                                label={field.label}
                                placeholder={field.placeholder}
                              />
                            ))}
                            <div className="hstack gap-2 justify-content-end">
                              {connection?.auth_type === "OAUTH" && (
                                <ButtonTheme
                                  loading={authorizeLoading}
                                  loadShowText={true}
                                  disabled={authorizeLoading}
                                  className="btn btn-outline-primary"
                                  onClick={authorize}
                                >
                                  {t("Re-authorize")}
                                </ButtonTheme>
                              )}
                              <ButtonTheme
                                loading={loading}
                                loadShowText={true}
                                disabled={loading}
                                className="btn btn-outline-primary"
                                onClick={handleUpdateSettings}
                              >
                                {t("Update Settings")}
                              </ButtonTheme>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
};

const ActionSetting = ({ initialValues, onChangeData = () => {}, onBack = () => {}, selectedAction, actionType }) => {
  const { t } = useTranslation();
  const validation = {
    enableReinitialize: true,
    initialValues: {
      enabled: initialValues?.enabled,
      rate_limit: initialValues?.rate_limit,
      max_retries: initialValues?.retry_settings?.max_retries,
      retry_delay: initialValues?.retry_settings?.retry_delay,
      retry_backoff: initialValues?.retry_settings?.retry_backoff,
      custom_settings: initialValues?.custom_settings ? JSON.stringify(initialValues?.custom_settings) : "",
      ...(actionType === "fetch_actions" && {
        schedule_type: initialValues?.schedule?.type,
        schedule_value: initialValues?.schedule?.value,
      }),
    },
    validationSchema: Yup.object({
      enabled: Yup.boolean(),
      rate_limit: Yup.string().required(t("Please Enter Rate Limit")),
      custom_settings: Yup.string().required(t("Please Enter Custom Settings")),
      ...(actionType === "fetch_actions" && {
        schedule_type: Yup.string().required(t("Please Select Schedule Type")),
        schedule_value: Yup.string().required(t("Please Enter Schedule Value")),
      }),
    }),
    onSubmit: (values) => {
      const isValidJsonString = isValidJson(values?.custom_settings);
      if (!isValidJsonString) {
        toast.error(t("Field custom settings is invalid json"));
        return;
      }

      const newData = {
        enabled: values.enabled,
        retry_settings: {
          max_retries: values.max_retries,
          retry_delay: values.retry_delay,
          retry_backoff: values.retry_backoff,
        },
        rate_limit: values.rate_limit,
        custom_settings: JSON.parse(values.custom_settings),
      };
      if (actionType === "fetch_actions") {
        newData.status = {
          last_run: null,
          next_run: null,
        };
        newData.response_mapping = {};
        newData.schedule = {
          type: values.schedule_type,
          value: values.schedule_value,
        };
      } else {
        newData.payload_template = {};
      }
      onChangeData(newData);
    },
  };
  const formRef = useRef();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={validation.initialValues}
      validationSchema={validation.validationSchema}
      onSubmit={validation.onSubmit}
      innerRef={formRef}
    >
      {({ values, submitForm, resetForm, setFieldValue }) => (
        <Form>
          <h5 className="card-title flex-grow-1 mb-0">
            {t(snakeToCapitalizedWords(selectedAction))} {t("Settings")}
          </h5>
          <div className="mt-2">
            <InputTheme type="checkbox" name={"enabled"} label="Enabled" formNoMargin={true} />
            <InputTheme name={"rate_limit"} label="Rate Limit" placeholder="Enter rate limit" />
            <InputTheme name={"max_retries"} label="Max retries" placeholder="Enter max retries" />
            <InputTheme name={"retry_delay"} label="Retry delay" placeholder="Enter retry delay" />
            <InputTheme name={"retry_backoff"} label="Retry backoff" placeholder="Enter retry backoff" />
            {actionType === "fetch_actions" && (
              <Fragment>
                <SelectTheme
                  label={"Schedule Type"}
                  name="schedule_type"
                  options={[
                    { id: "interval", name: "Interval" },
                    { id: "cron", name: "Cron" },
                  ]}
                />
                <InputTheme name={"schedule_value"} label="Schedule Value" placeholder="Enter schedule value" />
              </Fragment>
            )}
            <InputTheme name={"custom_settings"} label="Custom Settings" placeholder="Enter custom JSON settings" />
          </div>
          <div className="hstack gap-2 justify-content-end">
            <ButtonTheme
              className="btn btn-outline-primary"
              onClick={() => {
                onBack();
                resetForm();
              }}
            >
              {t("Back")}
            </ButtonTheme>
            <ButtonTheme className="btn btn-outline-primary" onClick={submitForm}>
              {t("Save")}
            </ButtonTheme>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(ConfigConnectionPage);
